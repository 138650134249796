<template>
  <Popup @popupClosed="popupClosed" :popupsData="popupsData">
    <template #body>
      <ImagePreview
        @removeImagePreview="removeImagePreview"
        @sendImage="sendImage"
        :images="images"
        v-if="images.status"
      />
      <div v-else class="canvas-video-wrapper h-full">
        <div
          v-if="attachment.text"
          class="absolute top-[7%] z-50 text-white w-full p-4 text-center"
          id="user-desc"
        >
          <span class="text-[18px] whitespace-break-spaces">
            {{ attachment.text }}
          </span>
        </div>
        <Vue3Lottie
          v-if="userMessageType === userMessageTypes.CAMERA_REAR"
          class="absolute"
          :loop="false"
          :animationData="FincanLottieShort"
        />

        <Vue3Lottie
          v-else-if="userMessageType === userMessageTypes.CUP_ONE_UPLOAD"
          class="absolute"
          :loop="false"
          :animationData="CupOneUpload"
        />

        <Vue3Lottie
          v-else-if="userMessageType === userMessageTypes.CUP_TWO_UPLOAD"
          class="absolute"
          :loop="false"
          :animationData="CupTwoUpload"
        />

        <div class="canvas-video-container h-full">
          <video
            muted="true"
            autoPlay="true"
            playsInline="true"
            ref="video"
            @canplay="initCanvas()"
            class="h-full object-cover"
          ></video>

          <canvas ref="canvas" style="display: none"></canvas>
        </div>
        <div
          :class="windowHeight > 620 ? 'h-[120px]' : 'h-[70px]'"
          class="shot-photo absolute w-full bottom-0 z-20 !bg-[rgba(63,54,54,0.80)] flex items-center justify-center !backdrop-blur-[30px]"
        >
          <button
            @click="shotPicture"
            type="submit"
            :class="
              windowHeight > 620
                ? 'w-[60px] h-[60px] text-[25px]'
                : 'w-[45px] h-[45px] text-[15px]'
            "
            class="shot-photo-button text-theme-primary rounded-full border border-theme-primary bg-white"
          >
            <Vue3Lottie
              :animationData="CameraLottie"
              class="!w-[40px] !h-[40px]"
            />
          </button>
        </div>
      </div>
    </template>
  </Popup>
</template>
<script setup>
  import { onMounted, reactive, ref, onUnmounted } from "vue"
  import CameraLottie from "/public/assets/lotties/camera_button.json"

  import ImagePreview from "@/components/composables/modal/cameramodal/ImagePreview.vue"
  import { deletePopup } from "@/utils/widget/popup"
  import FincanLottieShort from "/public/assets/lotties/camera_overlay_short.json"
  import CupOneUpload from "/public/assets/lotties/cup_one_upload.json"
  import CupTwoUpload from "/public/assets/lotties/cup_two_upload.json"
  import NativeCameraPermission from "@/components/composables/modal/cameramodal/NativeCameraPermission.vue"
  import services from "@/services/index"
  import { changeFormBlobToFormData } from "@/utils/settings"
  import { userSettings } from "@/store/userSettings"
  import { box } from "@/utils/box"
  import { userMessageTypes } from "@/utils/enums"
  const windowHeight = ref(window.innerHeight)

  const handleResize = () => {
    windowHeight.value = window.innerHeight
    document.getElementById("message-content").click() // Örnek olarak bir elementin click olayını tetikleyelim
  }

  const props = defineProps({
    popupsData: {},
  })

  const canvas = ref(null)
  const video = ref(null)
  const stream = ref(null)
  const fileInput = ref(null)

  const cameraError = reactive({
    message: null,
    constraint: null,
  })

  const { attachment, userMessageType } = props.popupsData.data

  console.log("userMessageType", userMessageType)

  class imagesModel {
    constructor() {
      this.imagePreview = null
      this.localImage = null
      this.status = false
      this.laoders = false
    }
  }

  const images = reactive(new imagesModel())

  const startCapture = () => {
    if (!navigator.mediaDevices) return
    navigator?.mediaDevices
      .getUserMedia({
        video: {
          facingMode: { exact: "environment" }, // Arka kamera
          width: { max: 1920 }, // Maksimum genişlik
          height: { max: 1080 }, // Maksimum yükseklik
          frameRate: { max: 30 }, // Maksimum kare hızı (60 fps daha akıcı video sağlar)
        },
        audio: false,
      })
      .then((s) => {
        video.value.srcObject = s
        video.value.play()
        stream.value = s
      })
      .catch((error) => {
        cameraError.message = error.name
        cameraError.constraint = error.constraint
        if (error.name === "OverconstrainedError") {
          alert(
            `Talep edilen ${error.constraint} aygıt tarafından desteklenmiyor. Lütfen farklı bir ayar deneyin.`
          )
          toggleNativeCamera()
        } else if (error.name === "NotAllowedError") {
          toggleNativeCamera()
        } else if (error.name === "NotFoundError") {
          toggleNativeCamera()
        } else {
          alert("Bir hata oluştu: " + error.message)
          toggleNativeCamera()

          // deletePopup(props.popupsData.id);
        }
      })
  }

  const toggleNativeCamera = () => {
    deletePopup(props.popupsData.id)

    box.openModal(
      NativeCameraPermission,
      { sendMessage: props.popupsData.data.sendMessage },
      "w-[80%]  bg-white dark:bg-dark-soft rounded"
    )
  }

  const initCanvas = () => {
    canvas.value.setAttribute("width", video.value.videoWidth)
    canvas.value.setAttribute("height", video.value.videoHeight)
  }

  const shotPicture = () => {
    let context = canvas.value.getContext("2d")
    context.drawImage(
      video.value,
      0,
      0,
      video.value.videoWidth,
      video.value.videoHeight
    )
    images.imagePreview = canvas.value.toDataURL("image/png")
    images.localImage = images.imagePreview
    images.status = true
    if (stream.value) {
      var videoTrack = stream.value.getVideoTracks()[0]
      videoTrack.stop()
      stream.value = null
    }
  }

  const removeImagePreview = () => {
    Object.assign(images, new imagesModel())
    startCapture()
  }

  const sendImage = async () => {
    if (images.laoders) return
    images.laoders = true
    const blob = changeFormBlobToFormData(images.imagePreview)
    var formData = new FormData()
    formData.append("image_file", blob, "filename.jpg")
    let query = ""
    await services
      .uploadImage({ sessionId: userSettings.session_id, payload: formData })
      .then((res) => {
        images.imagePreview = res.data.image_url

        query = res.data.query
        images.laoders = false
        props.popupsData.data.sendMessage({ image: images, query })
        Object.assign(images, new imagesModel())
      })
      .catch((err) => {
        console.log("err", err)
        images.laoders = false
      })
    deletePopup(props.popupsData.id)

    disabledCamera()
  }

  const popupClosed = () => {
    disabledCamera()
  }

  const disabledCamera = () => {
    if (stream.value) {
      var videoTrack = stream.value.getVideoTracks()[0]
      videoTrack.stop()
      stream.value = null
    }
  }

  onMounted(() => {
    startCapture()
    window.addEventListener("resize", handleResize)
  })

  onUnmounted(() => {
    window.removeEventListener("resize", handleResize)
  })
</script>
